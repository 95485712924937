.image {
  width: 30px;
  height: 30px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10%;
  gap: 1rem;
  /* width: 300px; */
}

.button {
  display: flex;
  margin-left: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
}

@media screen and (max-width:900px) {
  .text {
    /* display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%; */
    display: none
  }
}