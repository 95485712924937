.accounts_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background-color: #f7f7f7;
  overflow: hidden;
  width: 85% !important;
  height: 500px;
  overflow-y: auto;
}

.input_container {
  display: flex;
  flex-direction: row;
  width: 95%;
  padding: 3px;
}
.input_container1 {
  width: 90%;
  padding: 3px;
}
.input_container2 {
  width: 5%;
  
}

.card_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e4e4e4;
  width: 100%;
  padding: 1em;
}

.content_title {
  flex-direction: column;
  float: right;
  width: 50%;
  padding: 5px;
  height: 100%;
  font-weight: 600;
}

.chat_messages {
  display: flex;
  flex-direction: column;
  /* width:100%; */
  justify-content: space-around;
  height: 100%;
  /* background-color: blue; */
}

.chat_user {
  display: flex;
  /* width: 80%; */
  background-color: #eeeeee;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-end;
}

.user_con {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-bottom: 10px;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
}

.admin_con {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 10px;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.chat_admin {
  display: flex;
  /* width: 70%; */
  justify-content: center;
  background-color: #d2d9f4;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  align-self: flex-start;
  color: black;
}

.card_content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

@media (min-width: 490px) and (max-width: 1280px) {
  .accounts_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: #f7f7f7;
    overflow: hidden;
    width: 70%;
  }

  .card_content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: red; */
    width: 100%;
  }
}

@media screen and (max-width: 489px) {
    .input_container {
        display: flex;
        flex-direction: row;
        width: 85%;
        padding: 3px;
      }
      .input_container1 {
        width: 80%;
        padding: 3px;
      }
      .input_container2 {
        width: 5%;
        
      }
  .accounts_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: #f7f7f7;
    overflow: hidden;
    width: 80%;
  }

  .card_content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: red; */
    width: 100%;
  }
}
