.foldername {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: large;
  align-items: center;
  width: max-content;
  cursor: pointer;
  word-wrap: break-word;
}

p {
  word-wrap: break-word;
  word-break: break-all;
}

.foldername img {
  width: 80px;
  height: 80px;
}

.card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10%;
  flex-wrap: wrap;
  margin-left: 10%;
}

.create_sec {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.create_text {
  display: flex;
  background-color: #3d5ad0;
  padding: 10px;
  color: white;
  margin-left: 5%;
  border-radius: 5px;
}

@media screen and (max-width: 360px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10%;
    flex-wrap: wrap;
  }
}